/* navbar --------------------------------------------------------------*/
.navbar2 {
  display: flex;
  background-color: var(--bg-color);
  padding: 0;
  font-size: 1.5rem;
  width: 100%;
  height: 5.5rem;
  max-height: 5.5rem;
  justify-content: space-between;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
}

.navbar2 div h1 {
  font-size: 1.5rem;
  font-weight: bold;
}

.navbar2 img {
  height: 2.2rem;
}

.navbar2 .img-container {
  width: 15rem;
  padding: 0.8rem 0.8rem;
}

.dropdown-menu-personal {
  width: 100%;
  margin-right: 1px !important;
}

/* Sidebar ------------------------------------------------------*/
/* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.8s;
  cursor: pointer;
}
.ripple:hover {
  background: #282c34
    radial-gradient(circle, transparent 1%, #282c34 1%) center/15000%;
}
.ripple:active {
  background-color: #282c34;
  background-size: 100%;
  transition: background 0s;
}

.sidebar {
  width: 15rem;
  height: 100%;
  overflow: auto;
  flex-shrink: 0;
}

.sidebar ul {
  list-style: none;
  padding: 0.8rem 0.8rem;
}

.sidebar ul li {
  margin-bottom: 0.6rem;
  text-transform: capitalize;
  font-size: 0.83rem;
}

.sidebar .ul-interno li {
  margin-bottom: 0;
}

.sidebar .ul-interno {
  margin-left: 0.4rem;
  padding: 0.3rem;
}

.sidebar ul li a {
  text-decoration: none;
}

.active {
  background-color: #282c34;
}

/* Tabla ------------------------------------------------------------ */

.table.table-dark {
  width: 100% !important;
  font-family: sans-serif;
  overflow-x: auto;
}

.table.table-dark thead {
  font-size: 1rem;
  font-weight: bold;
}

.table.table-dark tbody tr th {
  font-size: 0.9rem;
  font-weight: 200;
  color: rgb(200, 200, 200) !important;
}

/* ---------------------- Showlist --------------------------*/
.showlist {
  background-color: var(--bs-gray-200);
  color: black;
  border-radius: 0.8em;
  position: relative;
}

.showlist ul {
  list-style: none;
}

.showlist ul li {
  text-align: center;
  border-radius: 2px;
  padding-left: 5px;
  padding-right: 5px;
}

.showlist ul li:hover {
  background-color: var(--bs-gray-500);
}

/* ------------------ Generic Card ---------------------------------*/
.card-noboot {
  box-sizing: border-box;
  width: 190px;
  background: #ddd;
  border: 3px solid rgb(255, 255, 255);
  box-shadow: 5px 5px 30px rgba(255, 255, 255, 0.258);
  backdrop-filter: blur(6px);
  border-radius: 17px;
  text-align: center;
  /*cursor: pointer;*/
  transition: all 0.5s;
  color: black;
}

.card-noboot:hover {
  border: 3px solid #198754;
  transform: scale(1.05);
}

.card-noboot:active {
  transform: scale(0.95) rotateZ(1.7deg);
}

/* -------------------- LOADER ------------------------------------*/

.loader {
  position: relative;
  width: 54px;
  height: 54px;
  border-radius: 10px;
}

.loader div {
  width: 8%;
  height: 24%;
  background: rgb(128, 128, 128);
  position: absolute;
  left: 50%;
  top: 30%;
  opacity: 0;
  border-radius: 50px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  animation: fade458 1s linear infinite;
}

@keyframes fade458 {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.25;
  }
}

.loader .bar1 {
  transform: rotate(0deg) translate(0, -130%);
  animation-delay: 0s;
}

.loader .bar2 {
  transform: rotate(30deg) translate(0, -130%);
  animation-delay: -1.1s;
}

.loader .bar3 {
  transform: rotate(60deg) translate(0, -130%);
  animation-delay: -1s;
}

.loader .bar4 {
  transform: rotate(90deg) translate(0, -130%);
  animation-delay: -0.9s;
}

.loader .bar5 {
  transform: rotate(120deg) translate(0, -130%);
  animation-delay: -0.8s;
}

.loader .bar6 {
  transform: rotate(150deg) translate(0, -130%);
  animation-delay: -0.7s;
}

.loader .bar7 {
  transform: rotate(180deg) translate(0, -130%);
  animation-delay: -0.6s;
}

.loader .bar8 {
  transform: rotate(210deg) translate(0, -130%);
  animation-delay: -0.5s;
}

.loader .bar9 {
  transform: rotate(240deg) translate(0, -130%);
  animation-delay: -0.4s;
}

.loader .bar10 {
  transform: rotate(270deg) translate(0, -130%);
  animation-delay: -0.3s;
}

.loader .bar11 {
  transform: rotate(300deg) translate(0, -130%);
  animation-delay: -0.2s;
}

.loader .bar12 {
  transform: rotate(330deg) translate(0, -130%);
  animation-delay: -0.1s;
}
/* ------------------ SearchingInfo ---------------------------------*/
.values-no-selected {
  min-height: 50vh;
  position: relative;
}

.values-no-selected .inner {
  border-radius: 5em 5em 5em 5em;
  position: relative;
  top: 8%;
  min-height: 50vh;
  min-width: 76vw;
  background-color: #282c34;
}

.values-no-selected .icon {
  margin-top: 6em;
  height: 10em!important;
  opacity: 40%;
}

/* ------------------ NoResult ---------------------------------*/
.no-result {
  min-height: 50%;
  position: relative;
}

.no-result .inner {
  border-radius: 5em 5em 5em 5em;
  position: relative;
  top: 8%;
  min-height: 50%;
  min-width: 76vw;
  background-color: #282c34;
}

.no-result .icon {
  margin-top: 6em;
  height: 10em!important;
  opacity: 40%;
}
/* ------------------ Alerts no assigned ------------------------ */
.values-no-selected .alert {
  border-radius: 5em 5em 5em 5em;
  position: relative;
  top: 8%;
  min-height: 34vh;
  min-width: 76vw;
  background-color: #282c34;
}