:root{
  --bg-color: #282c34;
  --gray-ligth:  #c4ccde;
  --gray: rgb(100,100,100);
  --blue-dark: #19376D;
}

body {
  max-width: 100vw;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important; */
  font-family: Arial, Helvetica, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* scrollbar-color: var(--bg-color) var(--gray); */
}
::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

::-webkit-scrollbar-thumb {
background-color: darkgrey;
outline: 0px solid slategrey;
}

/* Animations -----------------------------------------------------------*/


/* App general styles ---------------------------------------------------*/
.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display:flex;
  color: white;
  margin:0;
  padding:0;
  height: 100vh;
  overflow: auto;
}

.Main {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  overflow: auto;
}

.sidebar-show {
  display: auto;
}

.sidebar-hidden {
  display: none;
}

.page-link {
  cursor: pointer;
}

.swal2-container {
  z-index: 10000;
}

.p-dialog {
  max-height: 100%;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.3rem 0.3rem;
}

.p-toolbar {
  padding: 0.3rem 0.375rem;
}

.swal2-popup {
  background-color: #282c34 !important;
  color: #fff !important;
}
.swal2-title {
  color: #fff !important;
}
.swal2-content {
  color: #ccc !important;
}
.swal2-actions button {
  background-color: #444 !important;
  color: #fff !important;
}
.swal2-actions button:hover {
  background-color: #555 !important;
}